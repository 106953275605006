define("discourse/plugins/stemaway-ui-addons/discourse/controllers/analytics", ["exports", "@ember/controller", "discourse/lib/ajax", "@ember/service", "@ember/object"], function (_exports, _controller, _ajax, _service, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class AnalyticsController extends _controller.default {
    static #_ = (() => dt7948.g(this.prototype, "router", [_service.inject]))();
    #router = (() => (dt7948.i(this, "router"), void 0))();
    static #_2 = (() => dt7948.g(this.prototype, "currentUser", [_service.inject]))();
    #currentUser = (() => (dt7948.i(this, "currentUser"), void 0))();
    logs = null;
    pollTimer = null;
    topicScores = null;
    evaluations = null;
    points = 0;
    showPopup = false;
    popupContent = null;
    activeTab = 'activity';
    constructor() {
      super(...arguments);
      this.router.addObserver('currentRouteName', this, this.routeChanged);
    }
    routeChanged() {
      if (this.router.currentRouteName === 'analytics') {
        this.set('activeTab', 'activity');
        if (this.currentUser.username === this.model.username || this.currentUser.admin) {
          this.loadUserPoints();
          this.loadLogs();
        }
      }
    }
    willDestroy() {
      super.willDestroy(...arguments);
      this.router.removeObserver('currentRouteName', this, this.routeChanged);
    }
    loadUserPoints() {
      if (!this.model?.username) {
        return;
      }
      (0, _ajax.ajax)(`/user-points/${this.model.username}`).then(data => this.set('points', data.points)).catch(() => {
        this.set('points', 0);
        console.error('Error fetching user points');
      });
    }
    loadLogs() {
      if (!this.model?.username) {
        return;
      }
      (0, _ajax.ajax)(`/custom_search/logs/${this.model.username}`).then(data => this.set('logs', data.logs)).catch(error => console.error('Error fetching user logs:', error));
    }
    fetchUserAttempts() {
      return (0, _ajax.ajax)(`/custom_search/fetch-all-user-attempts/${this.model.username}`).then(response => {
        if (response.userAttempts) {
          this.set('userAttempts', response.userAttempts);
        } else {
          this.set('userAttempts', {});
        }
      }).catch(() => {
        this.set('userAttempts', {});
      });
    }
    fetchAllTopicScores() {
      if (!this.model?.username) {
        return;
      }
      return (0, _ajax.ajax)(`/custom_search/fetch-all-topic-scores/${this.model.username}`).then(response => {
        if ($.isEmptyObject(response.scores)) {
          this.set('topicScores', null);
        } else {
          this.set('topicScores', response.scores);
        }
      }).catch(error => {
        console.error('Error fetching topic scores:', error);
      });
    }
    fetchEvaluationFeedback() {
      if (!this.model?.username) {
        return;
      }
      return (0, _ajax.ajax)(`/custom_search/fetch-user-evaluation-scores?username=${this.model.username}`).then(response => {
        if (response.evaluations) {
          console.log('Evaluations:', response.evaluations);
          this.set('evaluations', response.evaluations);
        } else {
          this.set('evaluations', null);
        }
      }).catch(error => {
        console.error('Error fetching evaluation feedback:', error);
      });
    }
    async loadScoresAndEvaluations() {
      await this.fetchAllTopicScores();
      await this.fetchEvaluationFeedback();
      if (this.topicScores && this.evaluations) {
        for (let topicId in this.topicScores) {
          console.log(`Checking topicId: ${topicId}`);
          if (this.evaluations.hasOwnProperty(topicId)) {
            console.log(`Evaluation found for topicId: ${topicId}`);
            this.topicScores[topicId].evaluation = this.evaluations[topicId].evaluations;
          } else {
            console.log(`No evaluation found for topicId: ${topicId}`);
          }
        }
      }
      console.log('Combined Topic Scores:', this.topicScores);
    }
    static #_3 = (() => dt7948.n(this.prototype, "loadScoresAndEvaluations", [_object.action]))();
    showEvaluationLogs(evaluationData) {
      if (evaluationData && Array.isArray(evaluationData.evaluations)) {
        this.popupContent = this.formatEvaluationData(evaluationData.evaluations);
        this.set('showPopup', true);
        console.log('Popup Content:', this.popupContent);
      } else {
        console.error('Expected an object with evaluations array but got:', evaluationData);
        this.popupContent = '<p>No evaluation data available</p>';
        this.showPopup = true;
      }
    }
    static #_4 = (() => dt7948.n(this.prototype, "showEvaluationLogs", [_object.action]))();
    closePopup() {
      this.set('showPopup', false);
      this.set('popupContent', null);
    }
    static #_5 = (() => dt7948.n(this.prototype, "closePopup", [_object.action]))();
    toggleTab(tabName) {
      this.set('activeTab', tabName);
      if (tabName === 'activity') {
        this.loadLogs();
        this.loadUserPoints();
      } else if (tabName === 'scores') {
        this.fetchUserAttempts();
        this.loadScoresAndEvaluations();
      }
    }
    static #_6 = (() => dt7948.n(this.prototype, "toggleTab", [_object.action]))();
    formatEvaluationData(evaluationData) {
      if (!Array.isArray(evaluationData)) {
        console.error('Expected an array but got:', evaluationData);
        return '<p>Invalid evaluation data</p>';
      }
      let content = '<div class="evaluation-content-user-info-panel">';
      evaluationData.forEach(evaluation => {
        content += `
        <div class="evaluation-title-user-info-panel"><strong> Feedback </strong></div>
        <div class="feedback-user-info-panel">
          ${this.formatFeedback(evaluation.feedback)}
        </div>`;
      });
      content += '</div>';
      return content;
    }
    formatFeedback(feedback) {
      const feedbackItems = feedback.split(/(\d+\.\s)/).filter(Boolean);
      let formattedFeedback = '';
      for (let i = 0; i < feedbackItems.length; i += 2) {
        const number = feedbackItems[i].trim();
        const text = feedbackItems[i + 1].trim();
        formattedFeedback += `<p class="feedback-number-user-info-panel"><strong>${number}</strong> ${text}</p>`;
      }
      return formattedFeedback;
    }
  }
  _exports.default = AnalyticsController;
});